import React from "react";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
// import { graphql } from "gatsby";
import styles from "@styles/ParallaxBanner.scss";
import Img from "gatsby-image";

// const image = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/105988/banner.jpg";
// const image = require('@images/swing-fq-1200x400.jpeg');
const image = require('@images/strider1-full-4032x1500.jpeg');

// <Img fixed={data.file.childImageSharp.fluid} />

export default ({data}) => {
  // const image = data.file.childImageSharp.fluid;
  return (
    <div className="vertical">
      <div className={styles.bannerContainer}>
        <ParallaxBanner
          className={styles.bannerBg}
          layers={[
            {
              image,
              amount: 0.2,
            },
          ]}
        ><h1>Hi</h1></ParallaxBanner>
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query($image: String!) {
    file(
      name: { eq: $image }
      relativeDirectory: { eq: "swing-fq-1200x400.jpeg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
