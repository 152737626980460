import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

//strider1-full-4032x1500.jpeg

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "swing-1100x400.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
        >
          <div className="container has-text-centered hero-title"> 
              <h2 className="title">My Boulder Nanny</h2>
              <h1 className="subtitle slogan">by my boulder babysitter</h1>
            </div> 
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 50vh;
  min-height: 100px;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.2) 10%;
  background-size: cover;
`

export default StyledBackgroundSection