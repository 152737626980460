import React, { useEffect } from "react";
import { Link } from "gatsby";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import ParallaxImage from "@components/parallaxImage";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { graphql } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglass,
  faHourglassHalf,
  faBabyCarriage,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Img from "gatsby-image";
import styled from "styled-components";
import Layout from "@components/layout/layout";
import BackgroundSection from "@components/layout/hero";

const StyledLink = styled.a`
  color: white;
  &:hover {
    color: #fa7c91;
  }
`;

const IndexPage = ({ data }) => {
  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: false,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Layout>
      <div className="header-wrapper" id="home">
        <section className="hero">
          <BackgroundSection />
        </section>
      </div>
      <div className="main-content">
        <div className="section-light intro" id="intro">
          <div className="container">
            <div className="columns is-multiline">
              <div
                className="column is-12 has-vertically-aligned-content"
                // data-aos="fade-right"
                data-aos-duration="1"
              >
                <div className="column is-12 intro">
                  <h1 className="subtitle is-3 has-text-centered section-title">
                    Welcome!
                  </h1>
                </div>
                <p className="is-larger">
                  We're so happy that you are here and interested in finding
                  Experienced, Reliable, Responsible, Trustworthy, and Fun
                  support for your family! Our mission since 2012 for{" "}
                  <a href="https://myboulderbabysitter.com">
                    My Boulder Babysitter
                  </a>{" "}
                  has always been to find pre-vetted "nanny quality" occasional
                  babysitters (all bookable online) for our member families. We
                  will be utilizing all of the tools in our box, the skills
                  we've learned, and extensive resources to place{" "}
                  <span className="has-text-weight-bold">nannies</span>! We know
                  you'll come to love the care and attention we take while
                  creating each match!
                </p>

          
               
              
              </div>
            </div>
          </div>
        </div>


        {/* <ParallaxImage/> */}

        <div
          className="section-color dark-info-box services"
          id="dark-info-box"
        >
          <div className="container">
            <div
              className="column is-12 intro"
              data-aos="fade-in"
              data-aos-easing="linear"
            >
              <Link to="/families/how">
                <h1 className="title is-4 has-text-centered section-title">
                  SERVICES
                </h1>
              </Link>

              <h2 className="subtitle is-5 has-text-centered">
                We offer a variety of services to meet all your childcare needs.
              </h2>
              <br />
            </div>
            <div className="columns is-multiline">
              <div className="column is-half has-text-centered">
                <div data-aos="fade-in" data-aos-easing="linear">
                  <StyledLink href="/families/how">
                    <div>
                      <FontAwesomeIcon icon={faHourglass} size="3x" />
                      <hr />
                      <h2>Full-Time</h2>
                    </div>
                  </StyledLink>
                </div>
              </div>

              <div
                className="column is-half has-text-centered"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <StyledLink href="/families/how">
                  <div>
                    <FontAwesomeIcon icon={faHourglassHalf} size="3x" />
                    <hr />
                    <h2>Part-Time</h2>
                  </div>
                </StyledLink>
              </div>

              <div
                className="column is-half has-text-centered"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <StyledLink href="/families/how">
                  <div>
                    <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
                    <hr />
                    <h2>Temporary</h2>
                  </div>
                </StyledLink>
              </div>

              <div
                // is-offset-one-quarter
                className="column is-half has-text-centered"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <StyledLink href="/families/occasionalcare">
                  <div>
                    <FontAwesomeIcon icon={faBabyCarriage} size="3x" />
                    <hr />
                    <h2>Babysitting</h2>
                  </div>
                </StyledLink>
              </div>
            </div>
          </div>
        </div>

        <ParallaxImage/>

        <div className="section-light intro" id="intro">
          <div className="container">
            <div className="columns is-multiline">
              <div
                className="column is-12 has-vertically-aligned-content"
                // data-aos="fade-in"
                data-aos-duration="1"
              >
                
                {/* <br></br> */}
                <p className="is-larger has-text-greypurple has-text-centered">
                  Please do not hesitate to reach out to
                  myboulderbabysitter@gmail.com with any questions, comments, or
                  concerns! We are here, and happy to help support you whenever
                  you need us!
                </p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "baby-blanket2.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 380, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
